<template>
  <div class="feed-wrap">
    <div class="feed-list feed-column">
      <div class="admin-feed-block">
        <Spacer num="1" />
        <FeedTitle :title="$locale['notifications']" :subtitle="$locale['all_your_notifications']" />
        <NotificationsList />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {
    HomeFeedAside: () => import("../home/HomeFeedAside.vue"),
    NotificationsList: () => import("./NotificationsList.vue"),
  },
};
</script>
